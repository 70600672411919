<template>
  <div class="row justify-content-center">
    <!-- loader -->
    <div id="loader" style="display: none">
      <!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
    </div>
    <!-- * loader -->

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="#" class="headerButton goBack">
          <!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
        </a>
      </div>
      <div class="pageTitle"></div>
      <div class="right">
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule" class="col-sm-6 col-lg-4 col-xl-4">

      <div class="section mt-2 text-center" v-if="!showDateOfJoiningForm && !notEligibleTOEnroll">
        <h1>Enter Verification Code</h1>
        <h4>OTP has been sent to your registered phone.</h4>
      </div>
      <div class="section mb-5 p-2" v-if="!showDateOfJoiningForm && !notEligibleTOEnroll">

        <form action="index.html">
          <div class="">
            <div class="card-body pb-1">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <input @focus="otpError ? form.otp = '' : ''" v-model="form.otp" type="text" class="form-control verification-input" :class="{'is-invalid': otpError}" id="OTP" placeholder="••••••">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger " v-for="error of v$.form.otp.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div class="input-errors small text-danger " v-if="otpError">
                    <div class="error-msg">Invalid OTP</div>
                  </div>
                </div>
              </div>

              <div class="transparent mb-2 mt-2">
                <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="verifyOtp" :disabled="form.otp && form.otp.length !== 6"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Verify</span></button>
              </div>
            </div>
          </div>

        </form>
      </div>

      <div class="section mb-5 p-2" v-if="showDateOfJoiningForm">
        <div class="card">
            <div class="card-body pb-1">
              <form action="index.html">
                <div class="">
                  <div class="card-body pb-1">
                    <div class="form-group basic">
                      <div class="input-wrapper">
                        <label class="text-left" :style="{'width': '100%', 'text-align': 'left'}" v-if="isLabelShowed">date of joining</label>
                        <date-picker
                  v-model="form.joiningDate"
                  required
                  placeholder="date of joining"
                  :config="config"
                  @dp-change="datepickerFocus($event)"
                  @dp-show="datepickerFocus($event)"
                  ></date-picker>
                        <i class="clear-input">
                          <!--                    <ion-icon name="close-circle"></ion-icon>-->
                        </i>
                        <div class="input-errors small text-danger mt-1" :style="{width: 100, 'text-align': 'left'}" v-for="error of v$.form.joiningDate.$errors" :key="error.$uid">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="transparent mb-2 mt-2">
                      <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="checkJoiningDateValidity" :disabled="!form.joiningDate"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Submit</span></button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
        </div>
        
      </div>
       <div class="section mb-5 p-2" v-if="notEligibleTOEnroll">
        <div class="card">
            <div class="card-body pb-1 pt-4">
              <h2>You are not eligible to enroll!!</h2>
            </div>
            <div class="transparent p-2 pb-4">
                      <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="routeToLogin" ><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Back to Login</span></button>
            </div>
        </div>
       </div>

    </div>
    <!-- * App Capsule -->
    <b-modal id="modal-1" centered hide-header-close :hide-footer="true" :hide-header="true" size="lg" title="BootstrapVue">
      <p class="my-4 text-dark text-center">
        The phone number entered is not available in the Police Cooperative Society records.<br>
        If you are an existing member,
        <a class="link" @click.prevent="routeToHome">try again with another phone number.</a><br> or <a class="link" @click.prevent="enrollWithMemberId">Enroll with your Membership ID.</a><span class="mt-1 d-block">If you would like
        to Register as a New Member, Click on <b-button class="link " variant="primary" @click.prevent="routeToRegister">Register as a New Member</b-button></span>
      </p>
    </b-modal>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import useNotyf from "../composable/useNotify";
import { required, minLength } from '@vuelidate/validators'
import datePicker from "vue-bootstrap-datetimepicker";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import 'font-awesome/css/font-awesome.css'
export default {
  name: 'OtpVarification',
  data() {
    return {
      phone_number: null,
      isWaitingResponse: false,
      otpError: false,
      isLabelShowed: false,
      otpVerificationStatus: null,
      showDateOfJoiningForm: false,
      notEligibleTOEnroll: false,
      form:{
        'otp': null,
        'phone_number': this.$route.query.phone,
        'prospect_ids': JSON.parse(process.env.VUE_APP_PROSPECT_ID),
        'enrollment_validity': process.env.VUE_APP_ENROLLMENT_VALIDITY,
        'confirmation_email_template_id': process.env.VUE_APP_CONFIRMATION_EMAIL_TEMPLATE_ID,
        'employee_prospect_id': process.env.VUE_APP_EMPLOYEE_PROSPECT_ID,
        'joiningDate': null

      },
      // config: {
      //     format: 'DD-MM-YYYY',
      //     minDate: this.moment().subtract(70, 'years'),
      //     maxDate: this.moment(),
      //     date: this.moment(),
      // },      
      // config: {
      //     format: 'DD-MM-YYYY',
      //     minDate: this.moment().subtract(1, 'month').startOf('month'),
      //     maxDate: this.moment().subtract(1, 'month').endOf('month'),
      //     date: this.moment(),
      // },
      config: {
          format: 'DD-MM-YYYY',
          minDate: this.moment(process.env.VUE_APP_WINDOW_PERIOD_START_DATE, 'DD-MM-YYYY'),
          maxDate: this.moment(process.env.VUE_APP_WINDOW_PERIOD_END_DATE, 'DD-MM-YYYY'),
          date: this.moment(),
      },
    }
  },
  components: {
    datePicker
  },
  mounted() {
    console.log(process.env.VUE_APP_EMPLOYEE_PROSPECT_ID)
  },
  watch:{
    'form.otp': function () {
      if(this.otpError) this.otpError = false
    }
  },
  validations () {
    return {
      form: {
        otp: {
          required,
          minLength: minLength(6)
        },
        joiningDate: {
          required
        }
      }
    }
  },
  methods: {
    routeToLogin() {
      this.$router.push({
        path: '/login',
      })
    },
    datepickerFocus(e) {
      console.log('focused', e.target.value)
      if(!e.target.value || e.target.value == '') {
        this.isLabelShowed = false
      }
      this.isLabelShowed = true
    },
    checkJoiningDateValidity() {
      const windowPeriodStartDate = this.moment(process.env.VUE_APP_WINDOW_PERIOD_START_DATE, 'DD-MM-YYYY').subtract(1, 'day').format('DD-MM-YYYY')
      const windowPeriodEndDate = this.moment(process.env.VUE_APP_WINDOW_PERIOD_END_DATE, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY')
      console.log(windowPeriodEndDate, windowPeriodStartDate, 'st')
      // this.v$.form.joiningDate.$validate()
      // if(this.v$.form.joiningDate.$error) return           
      if(this.otpVerificationStatus.status === 1 && !this.otpVerificationStatus.data.enrollment_url) {          
        localStorage.setItem('LoggedUser', true)
        console.log(localStorage.getItem('LoggedUser', 'sss'))
        this.routeToRegister(this.otpVerificationStatus.data.member_data)
      } else {
        localStorage.setItem('LoggedUser', false)
        window.location.href = this.otpVerificationStatus.data.enrollment_url
      }
      // if(this.moment(this.form.joiningDate, 'DD-MM-YYYY').isBetween(this.moment(windowPeriodStartDate, 'DD-MM-YYYY'), this.moment(windowPeriodEndDate, 'DD-MM-YYYY'))) {
      // } else {
      //   this.showDateOfJoiningForm = false
      //   this.notEligibleTOEnroll = true
      // }
      
    },
    verifyOtp() {
      this.v$.form.otp.$validate()
      if(this.v$.form.otp.$error) return
      this.isWaitingResponse = true
      // let formD = new FormData();
      // formD.append('phone_number', this.$route.query.phone);
      // formD.append('otp', this.otp);
      // formD.append('prospect_ids[]',process.env.VUE_APP_PROSPECT_ID)
      // formD.append('enrollment_validity', process.env.VUE_APP_ENROLLMENT_VALIDITY)
      // formD.append('confirmation_email_template_id', process.env.VUE_APP_CONFIRMATION_EMAIL_TEMPLATE_ID)
      // formD.append('employee_prospect_id', process.env.VUE_APP_EMPLOYEE_PROSPECT_ID)
      this.$axios.post('otp-submit', this.form).then(({data}) => {
        this.isWaitingResponse = false
        if(data.status === 0) {
          this.otpError = true
          useNotyf().error(data.message)
        } else {
          this.otpVerificationStatus = data;
          this.showDateOfJoiningForm = false
          this.checkJoiningDateValidity()
        }
        //  else if(data.status === 1 && !data.data.enrollment_url) {
        //   // this.otpVerificationStatus = data;
        //   // localStorage.setItem('LoggedUser', true)
        //   // this.routeToRegister(data.data.member_data)
        //   //this.openModal()
        // } else {
        //   // localStorage.setItem('LoggedUser', false)
        //   // window.location.href = data.data.enrollment_url
        // }
      })
    },
    openModal() {
      this.$bvModal.show('modal-1')
    },
    routeToHome() {
      this.$router.push({
        path: '/'
      })
    },
    routeToRegister (data = null) {
      this.$store.commit('setMemberData', data)   
      this.$router.push({
        path: '/member-registration',
        query: {
          phone: this.$route.query.phone
        },
      })
    },
    enrollWithMemberId() {
      this.$router.push({
        path: '/enroll-with-membership-id',
        query: {
          phone: this.$route.query.phone
        }
      })
    }
  },
  setup: () => {
    return { v$: useVuelidate() }
  }
}
</script>

<style scoped lang="scss">
.verification-input {
  font-size: 32px !important;
  letter-spacing: 10px;
  text-align: center;
  border-radius: 10px !important;
  border: 1px solid #DCDCE9 !important;
  width: 195px !important;
  padding: 0 10px !important;
  margin: auto;
  min-height: 70px !important;
  font-weight: 700;
  color: #27173E !important;
  box-shadow: none !important;
  background: #FFF !important;
  &.is-invalid {
    border: 1px solid $colorDanger !important;
    color: $colorDanger !important;
  }
}
.link {
  cursor: pointer;
}
</style>
